/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {



// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Roots = {
  // All pages
  common: {
    init: function() {

    (function () {
 
    jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "date-eu-pre": function ( date ) {
        date = date.replace(" ", "");
         
        if ( ! date ) {
            return 0;
        }
 
        var year;
        var eu_date = date.split(/[\.\-\/]/);
 
        /*year (optional)*/
        if ( eu_date[2] ) {
            year = eu_date[2];
        }
        else {
            year = 0;
        }
 
        /*month*/
        var month = eu_date[1];
        if ( month.length == 1 ) {
            month = 0+month;
        }
 
        /*day*/
        var day = eu_date[0];
        if ( day.length == 1 ) {
            day = 0+day;
        }
 
        return (year + month + day) * 1;
    },
 
    "date-eu-asc": function ( a, b ) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
 
    "date-eu-desc": function ( a, b ) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    }
} );
     
    })();


    jQuery(function(){
      jQuery('#foi-table').dataTable({
        "info": false,
        "columnDefs": [
                   { "type": "date-eu", targets: [2, 5] }
               ]
      });
   });

    var form_valid = true;
    var show_messages = function(){
      var container = $('#bai_newsletter_signup_result');
        container.slideUp().removeClass('error').removeClass('success');
        container.empty();
        if(form_valid){
          container.addClass('success');
          container.append('Thank you for signing up <span>You will receive an email confirmation shortly.</span><span class="close-success"></span>');
          container.slideDown();
        } else {
          form_valid = true;
        }
      };
      $('#bai_newsletter_signup .ns-button').on('click',function(){

        var honeypot = validateNewsletterHoneyPot();
        if( honeypot == false ) {
          return false;
        }

        var form = $('#bai_newsletter_signup');
        var email_field = form.find('.ns-email-field:first');
        var firstname_field = form.find('.ns-firstname-field:first');
        var last_field = form.find('.ns-lastname-field:first');
        if(firstname_field.val().length === 0){
          form_valid = false;
          if(!firstname_field.hasClass('error')) {
            firstname_field.addClass('error');
          }
        } else {
          firstname_field.removeClass('error');
        }

        if(last_field.val().length === 0){
          form_valid = false;
          if(!last_field.hasClass('error')) {
            last_field.addClass('error');
          }
        } else {
          last_field.removeClass('error');
        }
        var email_reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
         if(!email_reg.test(email_field.val())){
          form_valid = false;
          if(!email_field.hasClass('error')) {
            email_field.addClass('error');
          }
        } else {
          email_field.removeClass('error');
        }
        show_messages();
        //return false;
      });

      $('#bai_newsletter_signup_result .close-success').on('click', function() {
        $(this).parent().slideUp().removeClass('success').empty();
      });

      //mobile navigation - open
      $('#mobile-nav-open-btn').on('click', function (e) {
        e.preventDefault();
        $('.mobile-nav').show();
        $('body').addClass('mobile-nav-open');
        $('body').removeClass('mobile-nav-closed');
        $(window).scrollTop(0);
      });

      //mobile navigation - close
      $('#mobile-nav-close-btn').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('mobile-nav-closed');
        $('body').removeClass('mobile-nav-open');

        // timeout required to avoid flicker
        setTimeout(function(){
          $('.mobile-nav').hide();
        }, 800);
      });

      // Validate honeypot
      function validateNewsletterHoneyPot() {
        if( !$('#honey').val() ) {
          return true;
        } else {
          console.log( $('#honey').val() );
          return false;
        }
      }

      // Consultations Section

      function goTo( link ) {
        var offset = $('header').height() - 200;
        console.log( offset );
        console.log( link );
        console.log( $('#'+link).offset().top - offset );

        $('html, body').animate({
          scrollTop: $('#'+link).offset().top - offset
        }, 0);
      }


      $('.js-scroll-to-anchor').on('click', function(e) {
        e.preventDefault();
        var link = $(this).attr("data-id");
        console.log( link );

        $("[id='" + link + "']").toggleClass('-active');
        $("[id='" + link + "']").siblings().toggleClass('-active');
        goTo( link );

      })

      $('.js-appendix-title').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('-active');
        $(this).siblings().toggleClass('-active');
      })

      $('.js-no-answer').on('change', function() {
        // Get parent data id
        var parent = $(this).parents().eq(2);
        var block_id = parent.data('id');

        var input_areas = parent.find('textarea');

        if( $(this).is(':checked') ) {
          $.each( input_areas, function() {
            $(this).removeAttr('required');
          });
        } else {
          $.each( input_areas, function() {
            $(this).attr('required', true);
          });
        };

      });

      $('.js-next').on('click', function(e) {

        e.preventDefault();

        var parent = $(this).parents().eq(1);

        //find textareas of section
        var input_areas = parent.find('textarea');
        //find checkbox of section
        var checkbox = parent.find('.js-no-answer');

        //initilise validate as false
        var validate = false;

        var has_content = 0;
        var no_content = 0;

        $.each( input_areas, function(index, data) {
          var value = $(this).val();
          console.log(value);

          if (value == "") {
            no_content += 1;
          } else {
            has_content += 1;
          }

          if (has_content < input_areas.length) {
            validate = false;
          } else {
            validate = true;
          }


        });

        if( validate || checkbox.is(':checked') ){
          //$(this).parent().parent().parent().children().toggleClass('-active');
          //open the next section
          $(this).parent().parent().parent().next().children().toggleClass('-active');
          $(this).removeClass('-error');

        } else {
          $(this).addClass('-error');
        };

      });

      $('.js-consultation-filter').on('change', function() {
        $('.js-filter-form').submit();
      });

      $('.js-submit-consultation').submit(function() {
        handleConsultationSubmission();

        return false;
      });

      function handleConsultationSubmission() {
        // Check if honeypot is filled
        if( $('.js-honeypot').val() ) {
          return false;
        }

        // Get attached file
        var fd = new FormData();
        var file = $('.js-submit-consultation').find('input[type="file"]');
        var individual_file = file[0].files[0];
        fd.append('file', individual_file);
        fd.append('action', 'consultation_submission');
        fd.append('post_data', $('.js-submit-consultation').serialize());

        $.ajax({
          data: fd,
          type: 'post',
          url: 'wp-admin/admin-ajax.php',
          contentType: false,
          processData: false,
          success: function( response ) {
            var response_data = $.parseJSON( response );
            var response_block = $('.js-consultation-response');
            console.log( response_data );
            if( response_data.error ) {

              if( response_block.hasClass('-success') ) {
                response_block.removeClass('-success');
              }

              response_block.addClass('-error');
              response_block.html( response_data.error );
            }

            if( response_data.success ) {

              if( response_block.hasClass('-error') ) {
                response_block.removeClass('-error');
              }

              response_block.addClass('-success');
              response_block.html( response_data.success );

              // window.location = '/en/consultations/thank-you/';
              var lang = splitUrl();

              if( lang == 'en' ) {
                window.location = '/' + lang + '/consultations-thank-you/';
              } else {
                // This will need to be changed to the irish version of this page
                window.location = '/' + lang + '/consultations-thank-you/';
              }

            }

          }
        });

      }

      function splitUrl() {
        var url = window.location.href;
        var lang = url.split('/');

        return lang[3];
      }

      // End Consultations section

      //sticky header

      $(window).scroll(function () {
        var header_height = $('header').outerHeight();
        var current_scroll_position = $(window).scrollTop();

        if (current_scroll_position >= header_height - 50) {
          $('body').addClass('fix-header');
        } else {
          $('body').removeClass('fix-header');
        }
      });


      //back to top link
      $('.back-to-top-btn').on('click', function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
      });

      // Adjustable font size
      var font_sizes = ["normal", "medium", "large"];
      var default_font_size = font_sizes[0];
      var font_size_css_class_base = "font-size-";


      function getCurrentFontSize() {
        return ($.cookie("font-size") || default_font_size);
      }

      function increaseAdjustedFontSize(current_font_size) {
        font_size_index = font_sizes.indexOf(current_font_size);
        if (font_size_index == -1) {
          new_font_size = default_font_size;
        } else {
          new_font_size_index = (font_size_index + 1)%font_sizes.length;
          new_font_size = font_sizes[new_font_size_index];
        }
        $.cookie("font-size", new_font_size);
        return new_font_size;
      }

      function removeFontSizeClasses() {
        activeFontSizeClasses = [];
        var classNames = $('body')[0].className.split(/\s+/);
        for (var i=0; i<classNames.length; ++i) {
          if (classNames[i].substr(0, "font-size-".length) === "font-size-") {
            activeFontSizeClasses.push(classNames[i]);
          }
        }
        console.log(activeFontSizeClasses.join(' '));
        if (activeFontSizeClasses.length > 0) {
          $('body').removeClass(activeFontSizeClasses.join(' '));
        }
      }

      function setAdjustedFontSize(new_font_size) {
        removeFontSizeClasses();
        new_font_size_css_class = font_size_css_class_base + new_font_size;
        $('body').addClass(new_font_size_css_class);
      }

      function initAdjustedFontSize() {
        setAdjustedFontSize(getCurrentFontSize());
      }

      $('.js-toggle-font-size').click(function(e) {
        e.preventDefault();
        setAdjustedFontSize(increaseAdjustedFontSize(getCurrentFontSize()));
      });
      initAdjustedFontSize();

     }

  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page

      $('#new_tweet').on('keydown', function (e) {
       //e.preventDefault();
        if (e.keyCode === 13) {
          var tweet_url = 'https://twitter.com/intent/tweet?via=BAItweets&text=';
          tweet_url += encodeURIComponent($(this).val());
          window.open(tweet_url, '_blank');
        } else {
          return true;
        }
      });
    }
  },
  // About us page, note the change from about-us to about_us.
  about_us: {
    init: function() {
      // JavaScript to be fired on the about us page
    }
  },
  page_template_template_broadcasters: {
    init: function() {
      function loadBroadcasters() {
        $.get(
          '/broadcasters_ajax.php',
          $('.js-select').serializeArray(),
          function (data) {
            $('.js-broadcasters-list').replaceWith(data);
          }
        );
      }

      $('.js-select').select2().on("change", loadBroadcasters);
      loadBroadcasters();
    }
  },
  page_template_template_complaints: {
    init: function() {
      function setActiveComplaintPath(path) {
        $('.js-complaints-path').removeClass('active');
        $('.js-complaints-path[data-path='+path+']').addClass('active');
      }

      $('.js-complain-mk-already-complained-toggle').change(function(e) {
        path = $(this).val();
        setActiveComplaintPath(path);
      });

      $('.js-complaints-path').removeClass('active');
      checked_inputs = $('.js-complain-mk-already-complained-toggle:checked');
      if (checked_inputs.length > 0) {
        setActiveComplaintPath(checked_inputs.val());
      }

      function setBroadcasterWebsite(website) {
        $('.js-go-to-broadcaster-btn').addClass('-active').attr('href', website);
      }

      $('.js-select').select2({
        placeholder: $('.js-select').attr('placeholder')
      }).on("change", function(e) {
        website = $(this).val();
        if (website && website !== '') {
          setBroadcasterWebsite(website);
        }
      });
      $('.add-complaint-button').on('click',function(){
        $('fieldset.complaint-files').find('.hide:first').removeClass('hide');
      });
      if ($('.js-select').val() !== '') {
        setBroadcasterWebsite($('.js-select').val());
      }
    }
  },
  page_template_template_contact: {
    init: function() {

      // create google map
      $('.acf-map').each(function(){
        render_map( $(this) );
      });

      // fix pan control not working on mobile
      $(document).on('touchstart', 'div[title*=Pan]', function () {
        $(this).trigger('click');
        return false;
      });
    }
  },
  page_template_template_news_listing: {
    init: function() {
      function loadNewsItems() {
        $.get(ajaxurl,{'form':$('.js-select').serialize(),'action':'filter_news'},
          /*'/news_ajax.php',
          $('.js-select').serializeArray(),*/
          function (data) {
            $('.js-news-list').replaceWith(data);
          }
        );
      }

      $('.js-select').select2().on("change", loadNewsItems);
      //loadNewsItems();

    }
  },
  page_template_template_publications: {
    init: function() {


      // timeout required to allow for google search box to load first
      setTimeout(function() {
        if ($('#gsc-i-id3').length > 0) {
          $('#gsc-i-id3').attr('placeholder', 'Search Publications');
        }
      }, 2000);

      // Change placeholder on Irish Site
      setTimeout(function() {
        if ($('.irish-site-search #gsc-i-id3').length > 0) {
          $('#gsc-i-id3').attr('placeholder', 'Foilseacháin cuardaigh');
        }
      }, 2000);



      var publication_table = $('#publications-table').dataTable({
        "lengthChange": false,
        "info": false,
        "oSearch":
          {
            "bSmart" : false
          },
        "columnDefs": [
          {
            "data": null,
            "defaultContent": null
          }
        ],
        "language": {
          "paginate": {
            "next": ">",
            "previous": "<"
          }
        },
        fnDrawCallback: function() {
          $("#publications-table thead").remove();
        }
      });
      publication_table.fnSort( [ [1,'desc'] ] ); // sort date initially

      $('#sort-table').on("change", function () {
        var selected = $('#sort-table option:selected').val();
        sort_table(publication_table, selected);
      });

      $('#filter-table').on("change", function () {
        var filter_val = $('#filter-table option:selected').val();
        filter_table(publication_table, filter_val);
      });

      // back to top - need to prepend this to pagination for layout
      if ($('.dataTables_paginate').length > 0) {
        $('.dataTables_paginate').prepend('<div class="back-to-top-container"><a href="#" class="back-to-top-btn">Back to top</a></div>');
      }

    }
  }
};


/*
* sort_table
*
* Sorts the publication table by name or date
*
*/

function sort_table(table, val) {
  switch (val) {
    case "name_asc":
      table.fnSort( [ [0,'asc'] ] );
      console.log("asc");
      break;

    case "date_desc":
      table.fnSort( [ [1,'desc'] ] );
      console.log("desc");
      break;
  }
  return;
}

/*
* filter_table
*
* Filters the publication table by topic category
*
*/

function filter_table(table,val) {
  table.fnFilter( val );
}


/*
*  render_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @return  n/a
*/

function render_map( $el ) {

  // var
  var $markers = $el.find('.marker');
  var isDraggable = $(document).width() > 767 ? true : false;
  // vars
  var args = {
    zoom    : 5,
    scrollwheel: false,
    draggable: isDraggable,
    panControl: true,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP,
  };

  // create map
  var map = new google.maps.Map( $el[0], args);
  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function(){

      add_marker( $(this), map );

  });

  // center map
  center_map( map );

}


/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $marker (jQuery element)
*  @param map (Google Map object)
*  @return  n/a
*/

function add_marker( $marker, map ) {

  // var
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

  // create marker
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });

  // add to array
  map.markers.push( marker );

  // if marker contains HTML, add it to an infoWindow
  if( $marker.html() )
  {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param map (Google Map object)
*  @return  n/a
*/

function center_map( map ) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each( map.markers, function( i, marker ){

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // only 1 marker?
  if( map.markers.length === 1 )
  {
    // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
  }
  else
  {
    // fit to bounds
    map.fitBounds( bounds );
  }
}

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Roots;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
